import React from "react"
import Layout from "../components/layout"
import HelmetEx from "../components/helmet-ex"

export const privacyPolicy = () => {
  return (
    <Layout>
      <section className="privacy-policy-section">
        <HelmetEx>
          <div metadata="title">Privacy Policy</div>
          <div metadata="keywords">
            indoor cycling, indoor cycle, interactive bike, streaming classes,
            virtual classes, stryde, stryde bike, virtual workouts
          </div>
          <div metadata="path">/faq</div>
          <div metadata="description">
            Access indoor cycling classes from country's top studios on an
            interactive bike at your home
          </div>
        </HelmetEx>
        <div className="container">
          <h4>Privacy Policy</h4>
          <p>
            We, Katana Fitness Inc., together with our affiliates (“Katana”,
            “we”, or “us”) provide a range of fitness products and services,
            including through our website (
            <a href="www.strydebike.com">www.strydebike.com</a>), social media
            accounts, and Stryde application installed on Stryde Interactive
            Bike (collectively referred to as “Services”). We compiled this
            privacy policy (“Privacy Policy”) to let you know what kind of
            information we may collect from you, how that information is used,
            and with whom it might be shared. If you do not agree to any part of
            this Privacy Policy or our Terms of Use, please do not use the
            Services.
          </p>
          <h4>1. Information collected</h4>
          <ul>
            <li>Name</li>
            <li>Postal address, including billing and shipping addresses</li>
            <li>Telephone number</li>
            <li>Email address</li>
            <li>
              Credit and debit card numbers. Note that we do not collect, store,
              or maintain your financial information. When you make purchases,
              the third-party application may collect financial information from
              you to process a payment on our behalf
            </li>
            <li>Account username</li>
            <li>Account password</li>
            <li>
              Equipment use history and metrics, for example which classes you
              participated in, the date you took those classes, and your
              performance metrics during the classes including total power
              output, cadence, distance and calories burned.
            </li>
          </ul>
          <p>
            Separately, we may collect data that your browser or mobile app
            sends when you access our Services (“Usage Data”). This Usage Data
            may include your computer's Internet Protocol address (e.g. IP
            address), cookie identifier, MAC address, IMEI, browser type,
            browser version, the pages of our Services that you visit, the time
            and date of your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
          </p>
          <h4>2. Cookie data</h4>
          <p>
            We and our partners may use tracking technologies, for example
            cookies, as part of our Services. A "cookie" is a piece of data
            stored on your computer that is tied to information about your
            internet browsing activity. Cookies allow us to collect information
            such as browser type, time spent on the Site, pages visited,
            language preferences, and other anonymous traffic data. We use this
            information to understand traffic and the usage of the Services,
            enabling us to improve the Services, and provide you with the best
            online experience possible. If you do not want to share information
            collected through the use of cookies, most browsers allow you to
            automatically decline cookies, or give you a choice of declining or
            accepting a particular cookie (or cookies) from a particular site.
            Note, however, that if you do not accept cookies, you may experience
            some inconvenience in your use of our Site.
          </p>
          <h4>3. Use of Data</h4>
          <p>
            We and our partners may use Personal Information for a range of
            purposes, including to:
          </p>
          <ul>
            <li>
              Respond to your questions and requests, complete your product
              delivery and provide you with other customer support
            </li>
            <li>
              Personalize the Services, including providing custom class
              recommendations
            </li>
            <li>Manage your account information</li>
            <li>Process payment information</li>
            <li>
              Send you updates regarding our Services, including marketing
              communications that may be of interest, and changes to our terms
              and conditions of use
            </li>
            <li>
              For business purposes which may include market research, fraud
              detection, and prevention of technical issues.
            </li>
          </ul>
          <h4>4. Third Party Websites and Analytics</h4>
          <p>
            Our site contains links to other websites, for example social media
            and websites of our content partners. These other domains and
            websites are not controlled by us, and we do not make any
            representations about third-party websites or social media
            platforms. We encourage you to read policies of each individual
            website and application with which you interact. We use third-party
            analytic and tracking tools, for example Google Analytics, to better
            understand who is using the Services, how users are interacting with
            the Services, how to improve the Services and to assist those
            parties in serving more targeted messages to you. Those third-party
            tools may use cookies, pixel tags or other technologies to collect
            and store non-Personal Information, for example pages visited, time
            spent on the page, device used to visit the page.{" "}
          </p>
          <h4>5. Disclosure of Data</h4>
          <p>
            We may share your Personal Information as described in this Privacy
            Policy or with your explicit permission:
          </p>
          <ul>
            <li>
              When you allow us to share your Personal Information with another
              company.
            </li>
            <li>
              With our partners who provide us with services that include order
              fulfillment, payment processing, information technology, data
              hosting, customer service, email delivery and other services.{" "}
            </li>
            <li>
              In connection with the sale of a business, including merger,
              acquisition, reorganization or sale of all or a material portion
              of its assets. If this were to happen, we will endeavor to guide
              the transferee to use Personal Information in a manner consistent
              with this Privacy Policy.{" "}
            </li>
            <li>
              To comply with applicable laws, including responding to requests
              from public and government authorities, to cooperate with law
              enforcement, or for other legal reasons.
            </li>
          </ul>
          <h4>6. Data Retention</h4>
          <p>
            We will retain your Personal Information for as long as you use our
            Services or as necessary to fulfill the purposes set out in this
            Privacy Policy. We will retain and use your Personal Information to
            comply with our legal obligations, conduct audits, pursue legitimate
            business purposes and enforce our contracts. When we are no longer
            required to retain the Personal Information, we will destroy, erase,
            or de-identify it.
          </p>
          <h4>7. Data Security</h4>
          <p>
            We take security of your data very seriously and attempt to
            implement and maintain reasonable technical and organizational
            security procedures and practices to safeguard the data we store, in
            order to protect it from unauthorized access, destruction,
            modification, or disclosure. Please be aware, however, that no data
            transmission or storage system is 100% secure and we are unable to
            guarantee the absolute security of Personal Information we have
            collected from you.{" "}
          </p>
          <h4>8. Children’s Privacy</h4>
          <p>
            Our Services are not directed to children under the age of 13. In
            addition, you are not permitted to use our Services if you do not
            meet the minimum age requirements to our Services in your
            jurisdiction. We do not knowingly collect Personal Information from
            anyone under the age of 13. If you are a parent or guardian and you
            are aware that your Child has provided us with Personal Information,
            please contact us at help@strydebike.com. If we learn that we have
            collected Personal Information of anyone under the age of 13, we
            will take steps to delete such information from our records as soon
            as possible.{" "}
          </p>
          <h4>9. Children’s Privacy</h4>
          <p>
            The Services and this Privacy Policy are intended for individuals
            residing in the United States. The Services are not intended to
            subject us to the laws or jurisdiction of any state, country or
            territory other than that of the United States.
          </p>
          <h4>10. Changes To Our Privacy Policy</h4>
          <p>
            We may update our Privacy Policy from time to time in which case we
            will update the “Last updated” date at the bottom of this Privacy
            Policy. You understand and agree that you will be deemed to have
            accepted the updated Privacy Policy if you use the Services after
            the updated Privacy Policy is posted on the Site. We encourage you
            to check the Site regularly to learn about changes to the Privacy
            Policy.
          </p>
          <h4>11. Contacting Us</h4>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at help@strydebike.com.
          </p>
          <p>
            <span>Last updated:</span>November 25, 2019
          </p>
        </div>
      </section>
    </Layout>
  )
}
export default privacyPolicy
